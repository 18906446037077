<template>
  <Toast />
  <header :class="$style.header">
    <div class="container py-5 flex align-items-center">
      <router-link  :to="{ name: 'dashboard'}" class="text-4xl text-white uppercase">Admin-panel</router-link>
      <router-link v-if="$store.state.auth == 2" :to="{ name: 'admins'}" :class="$style.link" class="text-lg ml-4">Управление администраторами</router-link>
      <router-link v-if="$store.state.auth > 0" :to="{ name: 'projects'}" :class="$style.link" class="text-lg ml-4">Проекты</router-link>
      <Button v-if="($store.state.auth > 0)" class="ml-auto" lable="Добавить операцию" @click="logOut">Выйти</Button>
    </div>
  </header>
  <form method="GET" action="/test" v-if="!($store.state.auth > 0)" class="py-5 w-50 relative" :class="$style.formAuth" @submit.prevent="onLogin">
        <input v-model="loginUser" class="inputlogin" type="text" placeholder="Логин">
        <input v-model="passwordUser" class="inputlogin" type="text" placeholder="Пароль">
        <Button type="submit" class="ml-auto mr-auto buttonAuth" lable="Авторизоваться">Авторизоваться</Button>
  </form>
  <router-view/>
  <!-- <upload-files></upload-files> -->
  <!-- <div>
    <div v-if="currentFile" class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>
    </div> --> 

    <!-- <label class="btn btn-default">
      <input type="file" ref="file" @change="selectFile" />
    </label>

    <button class="btn btn-success" :disabled="!selectedFiles" @click="upload">
      Upload
    </button> -->

    <!-- <div class="alert alert-light" role="alert">{{ message }}</div>

    <div class="card">
      <div class="card-header">List of Files</div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="(file, index) in fileInfos"
          :key="index"
        >
          <a :href="file.url">{{ file.name }}</a>
        </li>
      </ul>
    </div> -->
  <!-- </div> -->
</template>

<style lang="scss" module>
  .header {
    position: relative;
    background: #303030;
  }
  .link {
    color: #fff;
      &:hover {
        color: #ddd;
      }
  }
  .formAuth {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    padding-bottom: 100px;
    width: 100%;
    background-color: rgba(158, 158, 158, 0.459);
  }
  
</style>

<style>

@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&family=Russo+One&display=swap');
header {
  font-family: 'Russo One';
}
.inputlogin {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  margin-top: 30px;
  padding: 10px;
  border: 0;
  text-align: center;
  border-radius: 10px;
}

.buttonAuth {
  margin-top: 50px;
  height: 30px;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
}

</style>

<script>
    // import UploadFiles from "./components/UploadFiles";
    import Toast from 'primevue/toast'
    import Button from 'primevue/button';
    export default {
        methods: {
          async checkAuth(localstorage = false) {
            // const res = await fetch(`${this.$store.state.url}sql?password=${this.$store.state.password}&query=SELECT * FROM users`);
            // const answ = (await res.json())[0];
            if(localstorage == true) {
              this.$store.state.login = window.localStorage.getItem('login');
              this.$store.state.password = window.localStorage.getItem('password');
            }

            let answ = await this.$store.state.query(`${this.$store.state.url}sql?login=${this.$store.state.login}&password=${this.$store.state.password}&query=SELECT id FROM users LIMIT 0,1`);
            
            if(answ == 'auth') {
              this.$store.state.auth = 0;
            } else if(answ == 'auth_glav') {
              this.$store.state.auth = 2;
            } else {
              this.$store.state.auth = 1;
            }
          },
          onLogin() {
            this.$store.state.login = this.loginUser;
            this.$store.state.password = this.passwordUser;

            window.localStorage.setItem('login', this.loginUser);
            window.localStorage.setItem('password', this.passwordUser);

            this.checkAuth();
            this.$router.push("/dashboard");
          },
          logOut() {
            

            this.$store.state.login = '';
            this.$store.state.password = '';

            window.localStorage.setItem('login', this.loginUser);
            window.localStorage.setItem('password', this.passwordUser);
            this.checkAuth();
          }
        },
        created() {
          this.checkAuth(true);
          setInterval(() => {
            this.checkAuth();
          }, 15000)
        },
        components: {
          Button,
          Toast,
          // UploadFiles
        },  
        data() {
            return {
                text: null
            }
        },
        name: 'app',
        async mounted() {
            setInterval(() => {
              this.text++;
            }, 100)
        }
    }
</script>
